import React, { useEffect } from "react"
import Layer from "../components/Layer"
import Seo from "../components/Seo"
import da from "../js/dynamic_adapt"

export default function NotFound() {
	useEffect(() => {
		da.init()
	}, [])
	return (
		<>
			<Seo title="404"/>
			<Layer>
				<main>
					<h1>Oops. Something wrong</h1>
				</main>
			</Layer>
		</>
	)
}